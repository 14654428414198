import { Box, Flex } from 'grid-styled'
import { graphql, Link } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Map from '../components/Map/Map'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import Button from '../components/Button'
import styled from 'styled-components'
import Card from '../components/Card'
import { yPad } from '../utils/theme'

const Content = styled.div`
  max-width: 750px;
  width: 90%;
  margin: 80px auto 50px;

  h2 {
    font-weight: 400;
    margin-bottom: 1em;
  }

  span {
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    display: inline-block;
    margin-bottom: 1em;
  }
`

const Details = styled.section`
  background-color: #f9f8f7;
  padding: 100px;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
    i {
      color: ${(props) => props.theme.colors.primary};
      font-style: normal;
    }
  }

  section {
    display: flex;
    justify-content: space-around;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    padding: 100px 25px;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    div {
      width: 50%;
      margin-bottom: 40px;
    }
  }

  strong {
    font-weight: 500;
    margin-right: 1.5rem;
  }

  p {
    margin: 0;
  }
`

const ContactCards = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  ${yPad};
  padding-bottom: 0 !important;

  ${Box} {
    max-width: 450px;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-color: #f9f8f7;

    @media (min-width: 940px) {
      height: 155px;
    }
  }

  @media (max-width: 639px) {
    flex-direction: column;
    ${Box} {
      margin: 0 auto;
    }
  }
`

const openChat = (e) => {
  e.preventDefault()
  Calendly.showPopupWidget('https://calendly.com/lancecheung/')
}

export const ContactDetails = ({ title, children }) => (
  <Details>
    {title}
    <section>
      <div>
        <h4>Contact Details</h4>
        <p>
          <strong>Phone</strong> 07 3114 2500
        </p>
        <p>
          <strong>Email&nbsp;&nbsp;</strong> hello@parinity.com.au
        </p>
      </div>
      <div>
        <h4>Address</h4>
        <p>
          <strong>Physical</strong>
          <br />
          46 Samuel St <br />
          Camp Hill QLD 4152
        </p>
        <p style={{ marginTop: 10 }}>
          <strong>Postal</strong>
          <br />
          PO Box 65 <br />
          Camp Hill QLD 4152
        </p>
      </div>
      <div>
        <h4>Office Hours</h4>
        <p>
          <strong>Mon - Fri&nbsp;</strong> 8:30am - 5:00pm
        </p>
        <p>
          <strong>Weekend</strong> Saturdays by appointment
        </p>
      </div>
    </section>
    {children}
  </Details>
)

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>Contact Brisbane's Trusted Financial Advisers - Parinity</title>
      <meta
        name="description"
        content="Have a question, or want to find out what we can do for your business? Email us at any time and we'll get back to you shortly."
      />
    </Helmet>

    <Header>
      <h1>Contact us today</h1>
    </Header>

    <ContactCards>
      <Box mx={3}>
        <Link to="/contact#form">
          <Card
            shadow={true}
            border={true}
            color="primary"
            title="send an email"
            content="Have a question, or want to find out what we can do for your business? Email us at any time and we'll get back to you shortly."
          >
            {/* <Envelope /> */}
          </Card>
        </Link>
      </Box>
      <Box mx={3} onClick={openChat} style={{ cursor: 'pointer' }}>
        <Card
          shadow={true}
          border={true}
          color="tertiary"
          title="book an appointment"
          content="Want to skip straight to the good stuff. Book your free initial consultation with the Parinity team right here, right now."
        >
          {/* <Chat /> */}
        </Card>
      </Box>
    </ContactCards>

    <ContactDetails />

    <Map style={{ marginTop: 0 }} />

    <Contact
      id="form"
      style={{ paddingTop: '8rem', textAlign: 'left' }}
      noBorder={true}
    />

    <CTA
      title="Download our wealth tracking app"
      subtitle="A better tracking solution."
      button="Access Parinity Wealthdesk"
      inverted={true}
      to="https://parinity.wealthdesk.com.au/my/home"
    />
  </Layout>
)

export const query = graphql`
  query ContactPageQuery {
    womanWaterfall: file(relativePath: { eq: "track.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
