import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import { Button } from './Button'
import Input, { Textarea, Select } from './Input'

export const Form = styled.form`
  max-width: 650px;
  flex-basis: 75%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h2 {
    margin-bottom: 0.5rem !important;
    font-weight: 400;
    color: ${(props) => props.theme.colors.primary};
  }
  p {
    color: ${(props) => props.theme.colors.dark};
    margin: 0 auto 0.5rem !important;
    padding-right: 0 !important
    ;
    a {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

const Fieldset = styled.div`
  /* margin: -0.375rem; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  /* @media (min-width: 940px) {
    margin: -0.5rem;
  } */
`

interface Props {
  children?: React.ReactChild
  title?: string
  didFindOut?: boolean
  showMessage?: boolean
  showSuburb?: boolean
  content?: string
  id?: string
  type?: 'audit'
}

export default class extends React.Component<Props> {
  static defaultProps = {
    showMessage: true,
    content: '',
    didFindOut: false,
    showSuburb: true,
  }

  state = {
    buttonMessage: `Let's Chat`,
    email: '',
    message: '',
    name: '',
    phone: '',
    suburb: '',
    didFindOut: '',
    website: '',
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
    })
  }

  encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    // @ts-ignore
    // fbq('track', 'Lead', {
    //   content_category: 'form',
    //   content_name: window.location.pathname
    // })

    this.setState({ buttonMessage: 'Working...' })

    fetch('/.netlify/functions/send-email/', {
      body: JSON.stringify(this.state),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error()
        }
        this.setState({ buttonMessage: 'Thank You!' })
      })
      .catch(() => {
        this.setState({ buttonMessage: 'Error - Try later!' })
      })
  }

  render() {
    const { type, title, showMessage, showSuburb, didFindOut } = this.props
    const isAudit = type === 'audit'

    return (
      <Form name="contactForm" method="post" onSubmit={this.handleSubmit}>
        {title && <h2>{title}</h2>}
        {showMessage && (
          <p style={{ fontSize: didFindOut && '1.75em' }}>
            or give us a call on <a href="tel:0731142500">07 3114 2500</a>
          </p>
        )}
        {this.props.content && (
          <p
            style={{ padding: '20px 0', fontSize: '1.2rem' }}
            dangerouslySetInnerHTML={{ __html: this.props.content }}
          />
        )}
        <Fieldset>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            required={true}
            value={this.state.name}
            onChange={this.handleChange}
          />

          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Email address"
            required={true}
            value={this.state.email}
            onChange={this.handleChange}
          />
          <Input
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone number"
            required={true}
            value={this.state.phone}
            onChange={this.handleChange}
          />

          <Textarea
            id="message"
            name="message"
            placeholder="Message"
            rows={showMessage ? 3 : didFindOut ? 4 : 1}
            onChange={this.handleChange}
            value={this.state.message}
          />

          {didFindOut && (
            <Select
              id="didFindOut"
              name="didFindOut"
              rows={showMessage ? 3 : 1}
              onChange={this.handleChange}
              value={this.state.didFindOut}
            >
              <option value="">How did you find out about us?</option>
              <option value="Internet">Internet</option>
              <option value="Magazine">Magazine</option>
              <option value="Brochure">Brochure</option>
              <option value="Friend">Friend</option>
              <option value="Homeshow">Homeshow</option>
              <option value="Newspaper">Newspaper</option>
              <option value="Television">Television</option>
              <option value="Radio">Radio</option>
              <option value="Other">Other</option>
            </Select>
          )}

          <Box my={3}>
            <Button
              small={true}
              full={true}
              inverted={true}
              type="submit"
              onSubmit={this.handleSubmit}
            >
              {this.state.buttonMessage}
            </Button>
          </Box>
        </Fieldset>
      </Form>
    )
  }
}
